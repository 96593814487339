  
<div class="toprow">
  <button mat-icon-button (click)="close()"><i class="fas fa-times"></i></button>
  
  <p mat-dialog-title
    cdkDrag
     cdkDragRootElement=".cdk-overlay-pane" 
     cdkDragHandle>Materials</p>
  </div>
    <mat-dialog-content>



<mat-accordion>
<ng-container *ngFor="let shuttle of ms.materials">

  <form #materialForm="ngForm">
    <mat-expansion-panel >

      <mat-expansion-panel-header>
        <mat-panel-title>
          <i [style.color]="shuttle.color" class="fas fa-square"></i> {{shuttle.name}}
          </mat-panel-title>
          <mat-panel-description>
           
        </mat-panel-description>
      </mat-expansion-panel-header>
      
   
      <mat-form-field  class="is-fullwidth">
      <input matInput (colorPickerChange) = 'change()' placeholder="Color" class="colorpicker" [(colorPicker)]="shuttle.color" 
      [style.background]="shuttle.color" name="color" [(ngModel)]="shuttle.color"/>
      </mat-form-field>

      <mat-form-field  class="is-fullwidth">
      <input matInput placeholder="Material Name" name="name" [(ngModel)]="shuttle.name">
    </mat-form-field>

     
    <!-- <mat-form-field class="is-fullwidth">
      <mat-select placeholder="Yarn Type" name="type" [(ngModel)]="shuttle.type">
        <mat-option *ngFor="let type of types" [value]="type.value">
          {{ type.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

   <div class="is-fullwidth">
      <mat-form-field class="form-field">
        <input matInput  
        type="number" 
        placeholder="Yarn Thickness" 
        name="thickness" 
        min="0"
        max="100"
        step="10"
        (change)="change()"
        [(ngModel)]="shuttle.thickness"> 
        <span>&nbsp;&nbsp;%</span>
      </mat-form-field>

     
    </div>

    <!-- <div class="is-fullwidth">
       Insert From Left
       <mat-slide-toggle matInput (change)="change()" [(ngModel)]="shuttle.insert" name="insert" required>
       </mat-slide-toggle>
       Insert From Right
  </div> -->

  <div class="is-fullwidth">
      <mat-form-field class="is-fullwidth">
      <mat-label>Add Notes</mat-label>
      <textarea matInput [(ngModel)]="shuttle.notes" [ngModelOptions]="{standalone: true}">{{shuttle.notes}}</textarea>  	
    </mat-form-field>
  </div>

  <div class="is-fullwidth" >
    <button class="is-fullwidth" mat-flat-button color="primary" (click)="save(shuttle.id)"> <i class="fa fa-save" aria-hidden="true"></i> Save</button>
  </div>

      <div class="is-fullwidth" *ngIf="ms.materials.length > 1">
      <button class="is-fullwidth" mat-flat-button color="grey" (click)="delete(shuttle.id)"> <i class="fa fa-trash" aria-hidden="true"></i> Delete and Reassign to:</button>
      <mat-select lass="is-fullwidth" placeholder="Replacement" name="replacement" [(ngModel)]="replacements[shuttle.id]">
        <mat-option *ngFor="let rep of ms.materials" [value]="rep.id">
          {{ rep.name }}
        </mat-option>
      </mat-select>
    </div>

</mat-expansion-panel>

  </form>


</ng-container>

<mat-expansion-panel>

  <mat-expansion-panel-header>
    <mat-panel-title>
      <i class="fas fa-plus" (click) = 'addmaterial = !addmaterial'></i> Add Material
      </mat-panel-title>
      <mat-panel-description>
      
    </mat-panel-description>
  </mat-expansion-panel-header>

      <mat-form-field  class="is-fullwidth">
        <input matInput (colorPickerChange) = 'change()' placeholder="Color" class="colorpicker" [(colorPicker)]="newshuttle.color" 
        [style.background]="newshuttle.color" name="color" [(ngModel)]="newshuttle.color"/>
        </mat-form-field>

        <mat-form-field  class="is-fullwidth">
        <input matInput placeholder="Material Name" name="name" [(ngModel)]="newshuttle.name">
      </mat-form-field>

      
      <mat-form-field class="is-fullwidth">
        <mat-select placeholder="Yarn Type" name="type" [(ngModel)]="newshuttle.type">
          <mat-option *ngFor="let type of types" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    <div class="is-fullwidth">
        <mat-form-field class="form-field">
          <input matInput  
          type="number" 
          placeholder="Yarn Thickness" 
          name="thickness" 
          min="0"
          max="100"
          step="10"
          (change)="change()"
          [(ngModel)]="newshuttle.thickness"> 
          <span>&nbsp;&nbsp;%</span>
        </mat-form-field>

      
      </div>

      <div class="is-fullwidth">
        Insert From Left
        <mat-slide-toggle matInput (change)="change()" [(ngModel)]="newshuttle.insert" name="insert" required>
        </mat-slide-toggle>
        Insert From Right
    </div>

    <div class="is-fullwidth">
      <mat-form-field class="is-fullwidth">
      <mat-label>Add Notes</mat-label>
      <textarea matInput [(ngModel)]="newshuttle.notes" [ngModelOptions]="{standalone: true}">{{newshuttle.notes}}</textarea>  	
    </mat-form-field>

    <button mat-flat-button color="primary" (click)="addNewShuttle()"> Add This Material</button>
    </div>
  

</mat-expansion-panel>
</mat-accordion>


</mat-dialog-content>
<!-- <mat-dialog-actions>
  <button class="mat-raised-button"(click)="close()">Close</button>
  <button class="mat-raised-button mat-primary"(click)="save()">Save</button>
</mat-dialog-actions> -->