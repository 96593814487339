<div class="toprow">
  <button mat-icon-button (click)="close()"><i class="fas fa-times"></i></button>
  
  <p mat-dialog-title
    cdkDrag
     cdkDragRootElement=".cdk-overlay-pane" 
     cdkDragHandle>Draft View</p>
</div>


<mat-dialog-content>


  
  
    <div class="view_slider">
      <button mat-icon-button><i class="fas fa-search-plus"></i></button>
      <mat-slider invert
    tickInterval=".1" 
    step=".1"
    min="0" 
    max="2" 
    value="{{render.zoom}}" 
    (change)="zoomChange($event, 'slider')"></mat-slider>
    <button mat-icon-button><i class="fas fa-search-minus"></i></button>
  </div>  

 
  
   <div class="yarns-and-back">
      <div class="view_slider">
        View Yarns

        <mat-slide-toggle
            [checked]="render.current_view !== 'pattern'"
            (change)="viewChange($event)">
        </mat-slide-toggle>
      </div>



      <div class="view_slider">
        View Back
        <mat-slide-toggle
            [checked]="!render.view_front"
            (change)="front = !front; viewFront($event, front, 'front')">
        </mat-slide-toggle>
      </div>
    </div>
      <!-- <div class="view_slider">
        View Crossings
        <mat-slide-toggle
            [checked]="dm.isSelected('crossings', 'view_modes')"
            (change)="toggleCrossingView()">
        </mat-slide-toggle>
      </div> -->


      <!-- <div class = "system-matrix">

        <div class="system-row">
        <div class = "system-activate">
          show weft systems present on layer:
        </div>
        <div class = "warp-system">
          warp systems
         </div>
        <div class = "weft-system">
          weft systems
        </div>

      </div>

        <div *ngFor="let system_data of collective_systems;" class = "system-row">


          <div class = "system-activate">
            <button *ngIf="system_data.warp !== null" mat-stroked-button (click) = "showOnly(system_data.id)"> Layer {{system_data.id+1}}</button>
          </div>

          <div class = "warp-system">
        
            <mat-slide-toggle *ngIf="system_data.warp !== null"
            [matTooltip]=  system_data.warp.name
            [checked]="system_data.warp.visible"
            (change)="system_data.warp.visible = !system_data.warp.visible; visibleButton(system_data.id, system_data.warp.visible, 'warp')">
          </mat-slide-toggle>
          </div>

          <div class = "weft-system">
            <mat-slide-toggle *ngIf="system_data.weft !== null"
            [matTooltip]=  system_data.weft.name
            [checked]="system_data.weft.visible"
            (change)="system_data.weft.visible = !system_data.weft.visible; visibleButton(system_data.id, system_data.weft.visible, 'weft')">
          </mat-slide-toggle>
          </div>

      

        </div>


      </div> -->


    



</mat-dialog-content>
<!-- <mat-dialog-actions>
  <button class="mat-raised-button"(click)="close()">Close</button>
</mat-dialog-actions> -->
