<div *ngIf="tree.getNode(id) !== null && !no_draw" class="connection-container"
cdkDrag
[cdkDragDisabled] = disable_drag
[style.z-index] = "zndx"
[cdkDragFreeDragPosition]="bounds.topleft"

>

<svg id="svg-{{id}}"  width="200" height="200" xmlns="http://www.w3.org/2000/svg">

  </svg>


</div>


