<h2 mat-dialog-title>About</h2>

<mat-dialog-content>

    <div class="is-fullwidth">
  	  <p class ="mat-body">You are using AdaCAD version {{version}}</p>
  	  <p>---</p>


      <p class ="mat-body">AdaCAD is <a href="https://www.gnu.org/licenses/gpl-3.0.en.html" target="_blank">Free Software</a> created by the <a href="https://unstable.design" target="_blank">Unstable Design Lab</a> with support from  National Science Foundation Grants <a href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=1755587&HistoricalAwards=false" target="_blank">#1755587</a> and <a href="https://www.nsf.gov/awardsearch/showAward?AWD_ID=1943109" target="_blank">#1943109</a></p>

      <h3 class="mat-h3">Your Feedback is Important!</h3>
      <p class ="mat-body">We are excited to build this in order to explore the intersections between open source, computational design, weaving. Your feedback can help us make it better.  <a href="https://forms.gle/wWpdnikW8Vj8JcoWA" target="_blank">Tell us about your experience here</a></p>

      <h3 class="mat-h3">How To</h3>
      <p class ="mat-body"> We have prepared documents describing our software and features as well as how-to videos. You can find all available documentation at this link: <a href="https://unstabledesign.github.io" target="_blank">unstabledesign.github.io</a> or you can view our <a href="https://www.youtube.com/channel/UCRK7RAt8Z7Nw1u3aJ0FyPuQ" target="_blank">youtube channel</a></p>

      <h3 class="mat-h3">Contribute</h3>
      <p class ="mat-body"> If you are a developer and would like to contribute to this project you can do so via <a href="https://github.com/UnstableDesign/AdaCAD-weaver" target="_blank">GitHub</a> </p>

      <h3 class="mat-h3">Request Features via Github</h3>
      <p class ="mat-body"> If you would like to request features, you may do so by submitting an "issue" or feature request via <a href="https://github.com/UnstableDesign/AdaCAD-weaver" target="_blank">GitHub</a> or write to us directly via email at unstabledesignlab@gmail.com</p>



    </div>


    
</mat-dialog-content>
<mat-dialog-actions>
  <button class="mat-raised-button mat-primary"(click)="close()">Close</button>
</mat-dialog-actions>