
<div class="toprow">
  <button mat-icon-button (click)="close()"><i class="fas fa-times"></i></button>
  
  <p mat-dialog-title
    cdkDrag
     cdkDragRootElement=".cdk-overlay-pane" 
     [cdkDragFreeDragPosition]="bounds.topleft"
     cdkDragHandle>View</p>
  </div>

<mat-dialog-content>


      <div class="slider-row">
        <i class="fas fa-search-plus"></i>
      
    
          <mat-slider 
          invert
          tickInterval="1" 
          step=".1" 
          min="1" 
          max="15" 
          value="{{zoom}}" 
          (change)="zoomChange($event, 'slider')"></mat-slider>
          
          <i class="fas fa-search-minus"></i> 
      </div>
    
    
 

  <div id = "global" class="global_view">
    
    <svg width="250" height="250">
      <circle *ngFor="let obj of viewport.objs" [attr.cx]='obj.i.j * cell_factor' [attr.cy]='obj.i.i * cell_factor' [attr.r]="5" fill="black" />
    </svg>
    <div class="local"
    [style.width.px] = " viewport.getWidth() / zoom * cell_factor"
    [style.height.px] = " viewport.getHeight() / zoom * cell_factor"
    [cdkDragFreeDragPosition]="local_view.topleft"
    cdkDragBoundary=".global_view"
    (cdkDragMoved)="dragMove($event)"
	  (cdkDragEnded)="dragEnd($event)"
	  (cdkDragStarted)="dragStart($event)"
    cdkDragDisabled = 'false'
    cdkDrag>
    </div>
  </div>

  <!-- <div class="slider-row">
    <i class="fas fa-search-plus"></i>

      <mat-slider 
      invert
      [displayWith]="formatLabel" 
      tickInterval="1" 
      step="1" 
      min="1" 
      max="15" 
      value="{{zoom}}" 
      (change)="zoomChange($event, 'slider')"></mat-slider>
      
      <i class="fas fa-search-minus"></i>  </div>
 -->

</mat-dialog-content>



