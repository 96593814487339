<div class="toprow">
  <button mat-icon-button (click)="close()"><i class="fas fa-times"></i></button>
  
  <p *ngIf="type=='global'" mat-dialog-title
    cdkDrag
     cdkDragRootElement=".cdk-overlay-pane" 
     cdkDragHandle>Workspace Preferences</p>

     <p *ngIf="type=='local'" mat-dialog-title
     cdkDrag
      cdkDragRootElement=".cdk-overlay-pane" 
      cdkDragHandle>Draft Settings</p>
</div>


<mat-dialog-content>





  <p *ngIf="type === 'global'" class="description">The workspace can show you information about your drafts based on the defaults you provide here. You can change these at any time</p>


   <form #envt="ngForm" novalidate>

    <mat-form-field *ngIf="type==='global'">
      <mat-label>When viewing Draft Details, Place the (0,0) point at: </mat-label>
      <mat-select (selectionChange) ="originChange(envt)" [(value)]="selected_origin">
        <mat-option *ngFor="let option of origin_options" [value]="option.value">
          {{option.view}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    

      <!-- <div class="toggle" *ngIf="type === 'global'">      
        <mat-slide-toggle 
         matTooltip="select this if you want the drafts on the workspace to reflect the visual texture of the drafts. Don't select if you'd rather just focus on black and what structures"
        [(ngModel)]="global_loom.show_materials">Use colors</mat-slide-toggle> 
      </div> -->

      <!-- <div class="toggle" *ngIf="type === 'global'">      
        <mat-slide-toggle 
         matTooltip="select if black cells correspond to a heddle up (weft under warp)"
        [(ngModel)]="global_loom.black_cells_up">Black Cells are Heddle Up</mat-slide-toggle> 
      </div>

      <div class="toggle" *ngIf="type === 'global'">      
        <mat-slide-toggle 
         matTooltip="label threading patterns with numbers or just filled cells"
        [(ngModel)]="global_loom.number_threading">Use Numbers for Threading</mat-slide-toggle> 
      </div> -->


          <mat-form-field>
            <mat-label *ngIf="type=='global'">Default Loom Type</mat-label>
            <mat-label *ngIf="type=='local'">Loom Type</mat-label>
            <mat-select (selectionChange) ="loomChange(envt)" [(value)]="loomtype" [(ngModel)]="loomtype" name="loomtype">
              <mat-option *ngFor="let loomtype of loomtypes" [value]="loomtype.value">
                {{loomtype.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
  
  
        <mat-form-field *ngIf="type === 'local'">
          <input matInput name="warps" 
          [readonly]="warp_locked" 
          [(ngModel)]="warps" 
          type="number" 
          min="2" 
          placeholder="Warp Ends" 
          (change)="warpChange(envt)"
          (blur)="warpChange(envt)"> 
       
        </mat-form-field>

   <button *ngIf="type === 'local'" mat-icon-button [class.hide]="collapsed">    
    <i *ngIf="type === 'local'" aria-label="warp lock button" class="fa" [class.fa-lock]="warp_locked" [class.fa-unlock]="!warp_locked" (click)="warp_locked = !warp_locked"></i></button>
        <mat-form-field *ngIf="type === 'local'">
          <input matInput name="wefts" 
          [(ngModel)]="wefts" 
          type="number" 
          min="2" 
          placeholder="Weft Picks" 
          (change)="weftChange(envt)"
          (blur)="weftChange(envt)"> 
        </mat-form-field>
  
        <mat-form-field>
          <mat-label *ngIf="type=='global'">Default Density Units</mat-label>
          <mat-label *ngIf="type=='local'">Density Units</mat-label>            
          <mat-select [(value)]="units" [(ngModel)]="units" name="units" (selectionChange) ="unitChange(envt)">
            <mat-option *ngFor="let unit of density_units" [value]="unit.value">
              {{unit.viewValue}}
            </mat-option>
          </mat-select>
          </mat-form-field>
  
        <mat-form-field>
          <mat-label *ngIf="type=='global'">Default Density</mat-label>
          <mat-label *ngIf="type=='local'">Density</mat-label>    
          <input matInput  
          name="epi" 
          [(ngModel)]="epi" 
          type="number" 
          min= "0" 
          (change)="epiChange(envt)"
          (blur)="epiChange(envt)">  
  
        </mat-form-field>

        <ng-container *ngIf="units === 'cm'">
            <span matSuffix>ends / 10cm</span>
          </ng-container>
          <ng-container *ngIf="units == 'in'">
           <span matSuffix>epi</span> 
          </ng-container>
  
  
  
            <mat-form-field *ngIf="type === 'local'">
              <input matInput 
              name="width" 
              [(ngModel)]="width" 
              step="0.125" 
              type="number" 
              min= "0.25" 
              max ="60" 
              placeholder="Width" 
              (change)="widthChange(envt)"
              (blur)="widthChange(envt)"> 
            </mat-form-field>
  
          <ng-container *ngIf="type === 'local' && units === 'in'">
             <span matSuffix>inches</span> 
           </ng-container>
  
            <ng-container *ngIf="type === 'local' && units === 'cm'">
             <span matSuffix>cm</span> 
          </ng-container>
  
  
        <ng-container *ngIf="(loomtype == 'frame' || loomtype == 'direct')">
  
        <mat-form-field>
          <mat-label *ngIf="type=='global'">Default Number of Frames</mat-label>
          <mat-label *ngIf="type=='local'">Number of Frames</mat-label>    
          <input matInput 
          name="frames" 
          [(ngModel)]="frames"  
          step="1" 
          type="number" 
          min= "2" 
          max ="1000" 
          (change)="updateMinFrames(envt)" 
          (blur)="updateMinFrames(envt)"> 
           <span matSuffix>frames</span> 
         </mat-form-field>
  
  
        <mat-form-field  *ngIf="(loomtype == 'frame' || loomtype == 'direct')">
          <mat-label *ngIf="type=='global'">Default Number of Treadles</mat-label>
          <mat-label *ngIf="type=='local'">Number of Treadles</mat-label>      
          <input matInput 
          name="treadles" 
          [(ngModel)]="treadles" 
          step="1" 
          type="number" 
          min= "2" 
          max ="1000" 
          (change)="updateMinTreadles(envt)"
          (blur)="updateMinTreadles(envt)"
          > 
           <span matSuffix>treadles</span> 
         </mat-form-field>

         <!-- <mat-slide-toggle [(ngModel)] = 'global_loom.show_errors' name="show_errors">Label Drafts that Require More Frames or Treadles</mat-slide-toggle> -->

  
        </ng-container>
  
  </form>
  


</mat-dialog-content>
<!-- <mat-dialog-actions>
  <button class="mat-raised-button"(click)="close()">Close</button>
</mat-dialog-actions> -->
