  
<div class="toprow">
<button mat-icon-button (click)="close()"><i class="fas fa-times"></i></button>

<p mat-dialog-title
  cdkDrag
   cdkDragRootElement=".cdk-overlay-pane" 
   cdkDragHandle>Operations</p>
</div>
  

   <mat-dialog-content>

  
    <form  autocomplete="off">
    <mat-form-field  class="full-width" width='100%' appearance="fill">
    <mat-label><i class="fas fa-search"></i>search for action by name</mat-label>
    <input cdkFocusInitial type="text"
           placeholder=""
           matInput
           [formControl]="myControl"
           [matAutocomplete]="auto"
           >
           <mat-autocomplete 
           autoActiveFirstOption 
           #auto="matAutocomplete"
           (optionSelected) = addOpFromSearch($event)>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option}}
            </mat-option>
    </mat-autocomplete>
    </mat-form-field>
  </form>
   


    <ng-container *ngFor="let class of ops.classification">
      <div class="op-group">
      <h3>{{class.category}}</h3>
      <p> {{class.dx}}</p>
      <ng-container *ngFor="let op of class.ops">
          <button mat-stroked-button 
            [matTooltip] =op.dx
            matTooltipClass="visibleTooltip"
            [name]=op.name 
            (click)="addOp(op.name)">
            {{op.displayname}}
          </button> 
      </ng-container>
    </div>  
    </ng-container>

  </mat-dialog-content>


