<div class ="draft-detail-modal">


  <div class="toprow">
    <button mat-icon-button (click)="onSave()"><i class="fas fa-times"></i></button>
    
    <div class="titledata">
    <p mat-dialog-title
      cdkDrag
       cdkDragRootElement=".cdk-overlay-pane" 
       cdkDragHandle>Fine Tune this Draft</p>

      <p *ngIf="viewonly" class="viewonly">You are in <b>View Only Mode</b> because this draft has been generated by an operation. In viewonly mode, you may only adjust colors and warp systems and visualize yarn paths. If you want to modify the draft, you must go back to the mixer and duplicate it to remove it from the parent operation</p>
    </div>
    </div>
  

  <app-weaver #weaver
  [id]="id"
  ></app-weaver>


  <div class="draft-detail-footer">

    <div class="weave_stats">
      <span> loom type: {{loom_settings.type}}</span>
      <span> size: {{warps()}} ends, {{wefts()}} pics</span>
      <span> width: {{width()}} {{loom_settings.units}}</span>
      <span *ngIf="loom_settings.units == 'in'"> epi: {{loom_settings.epi}}</span>
      <span *ngIf="loom_settings.units == 'cm'"> ep10cm: {{loom_settings.epi}}</span>
      <span *ngIf="loom_settings.type !== 'jacquard'">requires {{numFrames()}} frames, {{numTreadles()}} treadles</span>
      <!-- <span class="error" *ngIf="ws.type !== 'jacquard' && (numFrames(loom) < loom_settings.frames || numTreadles(loom) < loom_settings.treadles) ">
        <i class="fas fa-exclamation-circle"></i> This draft requires more than the {{ws.min_frames}} frames or {{ws.min_treadles}} treadles than you have specified for your loom</span> -->
    </div> 


    <div class="ink-block">
      <mat-label>Using Ink</mat-label>
      <mat-select [(value)]="ink">
        <mat-option 
        *ngFor="let i of inks.getInks()" 
        [value]="i.viewValue"
      >
          {{i.viewValue}}
        </mat-option>
      </mat-select> 
    </div>

    <div>
      <mat-label>Editing Mode: </mat-label>
      <mat-slide-toggle
      [disabled]="loom_settings.type === 'jacquard'"
      (click)="swapEditingStyle()">
      </mat-slide-toggle>
      <div><mat-label>{{dm.getSelectedDesignMode('drawdown_editing_style').viewValue}}</mat-label></div>

      </div>
<!-- 
      <mat-slide-toggle
           [checked]="!render.view_front"
          (change)="front = !front; viewFront($event, front, 'front')" 
          
      </mat-slide-toggle> -->

</div>