<h2 mat-dialog-title>{{data.op.displayname}}</h2>
<mat-dialog-content>

  <div class="help-container">

    <div class="doc-container">
  	  <p>{{data.op.dx}}</p>
  	  <p *ngIf="!is_dynamic_op">Can take up to {{data.op.max_inputs}} input drafts</p>

      <div class="params">
        <ng-container *ngIf="data.op.params.length > 0"><h2> Inputs </h2></ng-container>
        <ng-container *ngFor="let param of data.op.params">
            <h3>Parameter Name: {{param.name}}</h3>
            <p>Parameter Description: {{param.dx}}</p>
            <p>Parameter Type: {{param.type}}</p> 
            <p *ngIf="param.type == number"> min/max value of this input: {{param.min}}-{{param.max}}</p>
		    </ng-container>

        <ng-container *ngIf="is_dynamic_op">
          <h4>This is a dyanmic operation, meaning that one of the parameters above generates multiple "inlets" to the function, each which can accept a draft and associate a particular value with that draft.</h4>
          <h3>Input that Controls the Inlets: {{dynamic_param_name}}</h3>
          <p>Inlet type: {{dynamic_type}}</p>
      </ng-container>

      </div>


      <!-- <div id="docs" [innerHTML]="documentation"></div>
      <h2>Documentation</h2> -->
    

      <h2> Code </h2>
      <pre>{{data.op.perform}}</pre>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="mat-raised-button mat-primary"(click)="close()">Close</button>
</mat-dialog-actions>