<div class="upload-container">

   <label>
      <input type="file" (change)="detectFiles($event)">
   </label>

   <div *ngIf="!uploading" class="upload-button">
      <button mat-button
            color="primary"
            [disabled]="!selectedFiles"
            (click)="uploadSingle();">
            Upload
      </button>
   </div>
   <div *ngIf="uploading" class="progress-bar">
      <mat-progress-bar mode="determinate" [value]="upSvc.progress"></mat-progress-bar>
   </div>
</div>
<canvas #uploadImage class="hidden"></canvas>