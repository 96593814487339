<mat-drawer-container  style="background: #3d3d3d;" #container class="mat-drawer-container" cdkScrollable>
  
  <mat-drawer #drawer class="example-sidenav" mode="side" position="end" opened>  

      <div class ="design-container">
       
        <!-- <app-tab
        (onGenerativeModeChange)="onGenerativeModeChange($event)"
        (onDraftSelected)="loadGeneratedDraft($event)"
        ></app-tab>  -->

        <app-sidebar #sidebar
        [id]="id"
        [render]="render" 
        source="weaver"
        (onDesignModeChange)='designModeChange($event)'
        (onZoomChange) ="renderChange($event);"
        (onViewChange) ="viewChange($event);"
        (onViewFront) ="renderChange($event);"
        (onHideWeftSystem)="hideWeftSystem($event);"
        (onShowWeftSystem)="showWeftSystem($event);"
        (onHideWarpSystem)="hideWarpSystem($event);"
        (onShowWarpSystem)="showWarpSystem($event);"
        (onGlobalLoomChange)="globalLoomChange($event)"
        (onLocalLoomNeedsRedraw)="redrawLoomAndDraft()"
        (onUpdateWarpSystems) = "updateWarpSystems($event)"
        (onUpdateWeftSystems) = "updateWeftSystems($event)"
        (onUpdateWarpShuttles) = "updateWarpShuttles($event)"
        (onUpdateWeftShuttles) = "updateWeftShuttles($event)"
        ></app-sidebar>


    </div>
    <div class="generative-options" *ngIf="weaveRef.selected==false">
      <h1>testing</h1>
      <button matTooltip="start making changes" 
      [name]='changesFromGenerative'
      class="button material is-small is-primary fas fa-square"
      >
    </button>
    </div>
  </mat-drawer>

    <app-draftviewer 
    [id]="id"
    [render]="render"
    source = "weaver"
    (onNewSelection) = "updateSelection($event)">
    </app-draftviewer>

</mat-drawer-container>
