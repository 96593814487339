
<!--TODO add z index support here-->
<div class="note-container" 
cdkDrag
[cdkDragFreeDragPosition]="bounds.topleft"
(cdkDragMoved)="dragMove($event)">


<div id="scalenote-{{note.id}}" class="subdraft-container">


  <div class="subdraft-contents">

    <div class="subdraft-details"> 

        <button cdkDragHandle class="button is-small is-primary fas fa-arrows-alt">
        </button>
  
  
      <button 
      matTooltip= "Delete"
      class="button is-small is-primary fas fa-times"
      [name]="delete"
      (click)="delete(note.id)">
      </button>
    </div>

    <div class="text-entry">
    
    <form class="example-form">
 
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Comment #{{note.id}}</mat-label>
          <textarea cdkTextareaAutosize matInput placeholder="something descriptive about your file..." [(ngModel)]="note.text" name="text" (focusout)="save()"></textarea>
        </mat-form-field>
    </form>

    </div>
 
 </div>


</div>
<canvas id="notecanvas-{{note.id}}" hidden></canvas>


</div>


