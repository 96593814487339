<div class="draft-container {{source}}" id="draft-container">


  
    <div id = 'draft-scale-container' class="origin{{ws.selected_origin_option}}">

      <app-selection 
      #selection
      [id]="id"
      [render]="render"
      (onCopy)="onCopy()"
      (onClear)="onClear($event)"
      (onPaste)="onPaste($event)"
      (onSelectionEnd)="onSelectionEnd()"
      ></app-selection>
     
      
      

      <div class="warp-systems-container origin{{ws.selected_origin_option}} ">
        <div class="warp-systems-text origin{{ws.selected_origin_option}}" id="warp-systems-text">
         
           <div class="warp-system-col origin{{ws.selected_origin_option}}" 
          *ngFor="let i of colShuttleMapping; let j = index;" >
      
            <span *ngIf="(j % render?.getTextInterval() == 0 && !isLastCol(j))" class="warp_number origin{{ws.selected_origin_option}}">{{j}}</span>      
            <span *ngIf="isLastCol(j)" class="warp_number_last origin{{ws.selected_origin_option}}">{{j+1}}</span>      

            <div  class="warp_selectors">
            <span *ngIf="!viewonly"class="warp_delete" (click)="deleteCol(j);"><i class="fa fa-trash"></i>
              </span>
      
            <span *ngIf="!viewonly" class="warp_clone"
            (click)="cloneCol(j);">
              <i class="fa fa-clone"></i>
            </span>
      
      
            <span *ngIf="!viewonly" class="warp_insert" 
            (click)="insertCol(j + 1);">
            <i class="fa fa-plus-circle"></i></span>
            </div>
         
          </div>

            <!-- <div class="warp-system-col" >
              <span class="last-warp">{{draft?.warps}}</span>
            </div>  -->

          </div>  

        <canvas id="warp-systems" #warp_systems>  </canvas>
        <canvas id="warp-materials" #warp_materials>  </canvas>
      </div>
      <div [class.hidden]="!isFrame" class="threading-tieup-container origin{{ws.selected_origin_option}} editable-{{dm.getSelectedDesignMode('drawdown_editing_style').value}}">
        <canvas id="threading" class=""></canvas>
        <div class="threading-tieup-spacer origin{{ws.selected_origin_option}}"></div>
        <canvas id="tieups" #tieups ></canvas>
      </div>

      <div class="drawdown-container origin{{ws.selected_origin_option}} editable-{{dm.getSelectedDesignMode('drawdown_editing_style').value}}" >
        <canvas id="drawdown" #drawdown  >  </canvas>
        <canvas [class.hidden]="!isFrame" id="treadling" #treadling></canvas>
        
        <div class="weft-systems-container origin{{ws.selected_origin_option}}">
          <canvas id="weft-materials" #weft_materials>  </canvas>
          <canvas id="weft-systems" #weft_systems>  </canvas>
          <div class="weft-systems-text origin{{ws.selected_origin_option}}" id="weft-systems-text">
            <div class="weft-system-row origin{{ws.selected_origin_option}}" 
            *ngFor="let i of (render?.visibleRows); let j = index;" >
        
  
                  <div class="weft_indicies">
                  <span *ngIf="(i % render?.getTextInterval() == 0 && !isLastRow(j))" class="weft_number origin{{ws.selected_origin_option}}">{{j}}</span>      
                  <span *ngIf="(isLastRow(i))" class="weft_number_last origin{{ws.selected_origin_option}}">{{i+1}}</span>      
                </div>
                  <span *ngIf="!viewonly" class = "weft_insert" 
                  (click)="insertRow(j, i)">
                  <i class="fa fa-plus-circle"></i>
                  </span>
        
                  <span *ngIf="!viewonly"class = "weft_clone" 
                  (click)="cloneRow(j, i);">
                  <i class="fa fa-clone"></i>
                  </span>
                  
                  <span *ngIf="!viewonly"class="weft_delete" 
                   (click)="deleteRow(i);">
                   <i class="fa fa-trash"></i>
                  </span>
            </div>
          </div> 
        </div>
      </div>


    
    
    
    
          

    
    
    </div>


 
 
 

 
          
  
 
 
   
     <!-- <svg #activeRow class="active_row">
     </svg>
 
     <svg #activeCol class="active_col">
     </svg>
                -->
 
 
     <canvas #bitmapImage hidden></canvas>


   </div>