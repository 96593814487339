
<div *ngIf="tree.getDraft(id) !== null" id="con-{{id}}" class="subdraft-container" [class.is_preview] = "is_preview"
	cdkDrag
	[style.z-index] = "zndx"
	[class.drawing] = "disable_drag && !is_preview"
	[class.no-pointer] = "disable_drag"
	[cdkDragDisabled] = "disable_drag"
	[cdkDragFreeDragPosition]="bounds.topleft"
	(cdkDragMoved)="dragMove($event)"
	(cdkDragEnded)="dragEnd($event)"
	(cdkDragStarted)="dragStart($event)">


	<div id="scale-{{id}}" class="subdraft-container">

	

			<div class="subdraft-details"> 
				
				<div class="cloak" [class.hide]="set_connectable || !draft_visible">
		
				
				
			<!-- <ng-container *ngIf="parent_id === -1"> -->
		
				<button 
				class="button is-small is-primary fas fa-expand-alt"
				matTooltip ="edit this draft"
				(click)="finetune()">
				</button>
		
				<button *ngIf='parent_id !== -1'
				matTooltip= "Show/Hide Draft"
				class="button is-small is-primary fas"
				[class.fa-eye-slash] = '!draft_visible'
				[class.fa-eye] = 'draft_visible'
				(click)="showhide()">
				</button>
		
		
				<button 
				matTooltip= "Duplilcate"
				class="button is-small is-primary fa fa-clone"
				[style.color]="button_color" 
				[name]="delete"
				(click)="designActionChange('duplicate')">
				</button>
				
				<button
				[matMenuTriggerFor]="downloadDropdown"
				class="button is-small is-primary fas fa-download"
				matTooltip ="download this draft">
				</button>
		
		
			
			
		
				<mat-menu #downloadDropdown mat-raised-button>
					<a  (click)="saveAsPrint()" mat-menu-item>as Printable Image</a>
					<a   (click)="saveAsBmp()" mat-menu-item> as Bitmap</a>
					<!-- <a  (click)="saveAsAda()" mat-menu-item> as AdaCAD file</a> -->
					<a  (click)="saveAsWif()" mat-menu-item> as Wif file</a>
				</mat-menu>
			
				<button *ngIf='parent_id === -1'
				matTooltip= "Delete"
				class="button is-small is-primary fas fa-times"
				[name]="delete"
				(click)="designActionChange('delete')">
				</button>
		
				</div>
				
			 </div>	

		<div  class="subdraft-contents" [class.active-selection] = has_active_selection>


				<canvas id="{{id}}" class="maindraft" [class.hidden] = '!draft_visible'>  
				</canvas>

		
		

			<div class="bottom-opts">
				<button
				id = "{{id}}-out" 
				matTooltip = "click here to use this draft as input"
				class="button is-small output outlet"
				(click) = "connectionStarted(id)"
				>
				<i class="fas fa-chevron-down"></i>
				</button>


				<button *ngIf='!draft_visible' 
				matTooltip= "Show/Hide Draft"
				class="button is-small is-primary"
				(click)="showhide()"
				>
				<i class="fas fa-eye-slash"></i>		  
				</button>
			
					
				<div class='dims_and_name'>
					<div>
						<input matInput  [(ngModel)]="ud_name" 
						[placeholder]="tree.getDraft(id).gen_name"  
						(focusout)="nameFocusOut($event)"
						(change)="updateName()">
					 </div>
					<div class="dims">{{warps()}} x {{wefts()}} </div>
					<!-- <div *ngIf="ws.type === 'frame' && (tree.getLoom(id).min_frames < tree.getLoom(id).num_frames || tree.getLoom(id).min_treadles < tree.getLoom(id).num_treadles) "class="frame">
						<span class="error">
							<i matTooltip="This draft requires more than the {{gl.min_frames}} frames or {{gl.min_treadles}} treadles than you have specified for your loom" class="fas fa-exclamation-circle"></i> 
							{{tree.getLoom(id).num_frames}} frames,  {{tree.getLoom(id).num_treadles}} treadles</span></div>
				</div> -->

			</div>

		
		</div>





   <canvas #bitmapImage hidden></canvas>

</div>
	

