
<div class="toprow">
  <button mat-icon-button (click)="close()"><i class="fas fa-times"></i></button>
  
  <h2 mat-dialog-title>Open a Workspace</h2>

</div>


<mat-dialog-content>


  <form #initForm="ngForm">

      <ng-container>
        <mat-form-field appearance="fill">
          <mat-label>Where would you like to begin?</mat-label>
        <mat-select [(value)]="selected" (selectionChange)="selectionMade(selected)">
          
          
          <mat-option *ngFor="let opt of opts" [value]="opt.value">
            {{opt.viewValue}}
          </mat-option>
        </mat-select>
        </mat-form-field>
      </ng-container>


    <ng-container *ngIf="(selected == 'new')">
      <p>just so you know, you'll be able to change these values at any time</p>

      <mat-form-field appearance="fill">
          <mat-label>Which type of loom are you designing for?</mat-label>
          <mat-select [(value)]="loomtype" [(ngModel)]="loomtype" name="loomtype">
          <mat-option *ngFor="let type of dm.loom_types" [value]="type.value">
            {{type.viewValue}}
          </mat-option>
        </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
        <input matInput  type="number" placeholder="Number of Warp Ends" name="warps" min="2" step="1" [(ngModel)]="warps"  required (change) = "valid = 1">
        </mat-form-field>

       <mat-form-field appearance="fill">
        <input matInput  type="number" placeholder="Number of Weft Picks" name="wefts" min="2" step="1" [(ngModel)]="wefts"  required (change) = "valid = 1">
        </mat-form-field>


      <mat-form-field appearance="fill">
          <mat-label>Density Units</mat-label>
          <mat-select [(value)]="units" [(ngModel)]="units" name="units">
          <mat-option *ngFor="let unit of dm.density_units" [value]="unit.value">
            {{unit.viewValue}}
          </mat-option>
        </mat-select>
        </mat-form-field>

       <mat-form-field appearance="fill">
        <input matInput  type ="number" placeholder="Weave Density" name="epi" value="10" [(ngModel)]="epi">
       </mat-form-field> 




      <ng-container *ngIf="(loomtype == 'frame') || (loomtype == 'direct')">
        <mat-form-field appearance="fill">
         <input matInput  type="number" placeholder="Number of Shafts" name="frame_num" min="2" step="1" value="8" [(ngModel)]="frame_num">
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="(loomtype == 'frame') ">
     <mat-form-field appearance="fill">
      <input matInput  type ="number" placeholder="Number of Treadles" name="treadle_num" [(ngModel)]="treadle_num" min="2" step="1" value="10">
     </mat-form-field> 
    </ng-container>

   

    </ng-container>

    
    <ng-container *ngIf="(selected == 'ada' || selected == 'bmp' || selected == 'wif' || selected == 'jpg')">
    <upload-form type="init" (onData)="handleFile($event)" class="button"></upload-form>
    <div class="error">{{error}}</div> 
  </ng-container>

  <ng-container *ngIf="(selected == 'example')">
    <div class="preloaded_files">
      <mat-card >
        <mat-card-title>Praxis Workshop
          <button (click)="loadExample('praxis-workshop')" mat-raised-button color="white">Load</button>

        </mat-card-title>
        <img mat-card-image src="/assets/example_img/praxis-workshop.png" alt="Screenshot of the File Contents">
          <mat-card-content>
            <p>
              A file that will walk us through the workshop at praxis. 
            </p>
          </mat-card-content>
        <mat-card-actions>
          <button (click)="loadExample('praxis-workshop')" mat-raised-button color="primary">Load</button>
        </mat-card-actions>
      </mat-card>
     
      <mat-card >
        <mat-card-title>Introduction, Lesson 1
          <button (click)="loadExample('lesson1')" mat-raised-button color="white">Load</button>

        </mat-card-title>
        <img mat-card-image src="/assets/example_img/lesson1.png" alt="Screenshot of the File Contents">
          <mat-card-content>
            <p>
              If this is your first time using AdaCAD, start here to learn our vocabulary and how you can go
            </p>
          </mat-card-content>
        <mat-card-actions>
          <button (click)="loadExample('lesson1')" mat-raised-button color="primary">Load</button>
        </mat-card-actions>
      </mat-card>
     
      <mat-card >
        <mat-card-title>A Sample Draft
          <button (click)="loadExample('intro')" mat-raised-button color="white">Load</button>

        </mat-card-title>
        <img mat-card-image src="/assets/example_img/intro.png" alt="Screenshot of the File Contents">
          <mat-card-content>
            <p>
             If this is your first time using AdaCAD, start here for some quick tips. 
            </p>
          </mat-card-content>
        <mat-card-actions>
          <button (click)="loadExample('intro')" mat-raised-button color="primary">Load</button>
        </mat-card-actions>
      </mat-card>

      <mat-card >
        <mat-card-title>Operations Playground
          <button (click)="loadExample('operation_playground')" mat-raised-button color="white">Load</button>
        </mat-card-title>
        <img mat-card-image src="/assets/example_img/operation_playground.png" alt="Screenshot of the File Contents">
          <mat-card-content>
            <p>
              A file that shows all possible operations currently supported by AdaCAD, allowing you to manipulate and play
            </p>
          </mat-card-content>
        <mat-card-actions>
          <button (click)="loadExample('operation_playground')" mat-raised-button color="primary">Load</button>
        </mat-card-actions>
      </mat-card>

      <mat-card>
        <mat-card-title>E-Textiles Sampler
          <button (click)="loadExample('etextile')" mat-raised-button color="white">Load</button>
        </mat-card-title>
        <img mat-card-image src="/assets/example_img/etextile.png" alt="Screenshot of the File Contents">
          <mat-card-content>
            <p>
              Shows partiuclar techniques that can be particularly useful for creating e-textile LED circuits, force sensors, or electrodes
            </p>
          </mat-card-content>
        <mat-card-actions>
          <button (click)="loadExample('etextile')" mat-raised-button color="primary">Load</button>
        </mat-card-actions>
      </mat-card>

      <mat-card>
        <mat-card-title>Woven Electrode Sample
          <button (click)="loadExample('electrode')" mat-raised-button color="white">Load</button>
        </mat-card-title>
        <img mat-card-image src="/assets/example_img/electrode.png" alt="Screenshot of the File Contents">
          <mat-card-content>
            <p>
              Presents strategies and patterns for drafting woven electrodes. 
            </p>
          </mat-card-content>
        <mat-card-actions>
          <button (click)="loadExample('electrode')" mat-raised-button color="primary">Load</button>
        </mat-card-actions>
      </mat-card>

      <mat-card>
        <mat-card-title>Two Pick Structures
          <button (click)="loadExample('twopic')" mat-raised-button color="white">Load</button>
        </mat-card-title>
        <img mat-card-image src="/assets/example_img/twopic.png" alt="Screenshot of the File Contents">
          <mat-card-content>
            <p>
              Two pick structures can create really interesting graphic patterns in the cloth and you can a apply a set of operations to make any weft-facing structure a 2 pic structure
            </p>
          </mat-card-content>
        <mat-card-actions>
          <button (click)="loadExample('twopic')" mat-raised-button color="primary">Load</button>
        </mat-card-actions>
      </mat-card>

      <mat-card>
        <mat-card-title>Mapping Layers to an Image
          <button (click)="loadExample('threelayer')" mat-raised-button color="white">Load</button>
        </mat-card-title>
        <img mat-card-image src="/assets/example_img/threelayer.png" alt="Screenshot of the File Contents">
          <mat-card-content>
            <p>
            We create three variations of a three layer weave structure, each which has a different layer on top. We then map each of those to an image.
            </p>
          </mat-card-content>
        <mat-card-actions>
          <button (click)="loadExample('threelayer')" mat-raised-button color="primary">Load</button>
        </mat-card-actions>
      </mat-card>

 

      <!-- <mat-card>
        <mat-card-title>Floor Loom</mat-card-title>
        <img mat-card-image src="/assets/example_img/etextiles.png" alt="Screenshot of the File Contents">
          <mat-card-content>
            <p>
              Shows partiuclar techniques that can be particularly useful for creating e-textile LED circuits, force sensors, or electrodes
            </p>
          </mat-card-content>
        <mat-card-actions>
          <button (click)="loadExample('etextiles')" mat-raised-button color="primary">Load</button>
        </mat-card-actions>
      </mat-card> -->

      <!-- <button mat-button color="primary" (click)="loadExample('operation_playground')">Operations Playground</button>
      <p>A file that shows all possible operations currently supported by AdaCAD, allowing you to manipulate and play</p>

      <button mat-button color="accent">Floor Loom Drafting Example</button>
      <p>A file that shows how one might design for floor looms useing AdaCAD</p>

      <button mat-button color="accent">Jacquard Drafting Example</button>
      <p>A file that shows how one might design for floor looms useing AdaCAD</p>

      <button mat-button color="accent">E-Textile Structures</button>
      <p>A file containing several possible weaving structures for common e-textile operations (e.g. LED circuit, force sensing, electrodes)</p>
 -->


    </div>    
  </ng-container>




</form>


</mat-dialog-content>

 <ng-container *ngIf="(selected !== 'example')">
    <mat-dialog-actions>
        <button [disabled]="!warps && !valid" class="mat-raised-button mat-primary" (click)="save(initForm, loomtype, epi, frame_num, treadle_num)">Load</button>
    </mat-dialog-actions>
 </ng-container>