<div class="snackbar">

    <!-- <ng-container *ngIf="bounds !== null">
        <div>dims:({{bounds.width}}, {{bounds.height}})</div>
   </ng-container> -->
    <ng-container *ngIf="message !== null"> 
        <div class="pink">{{message}}</div>
    </ng-container>


</div>